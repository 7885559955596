import React from 'react';
import {
  ChakraProvider,
  VStack,
  theme,
  Flex,
  Image,
  Spacer,
  Heading,
  ReactSelect
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { FaInstagram, FaLinkedin, FaGithub, FaFacebook, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
// import { Logo } from './Logo';
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react';
import Header from './components/Header';
import Footer from './components/Footer';
import NavBar from './components/NavigationBar';
import Body from './components/Body';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <NavBar w="100%"></NavBar>
      <VStack w="100%" p="default">
      <Header></Header>
      <Body></Body>
      <Footer></Footer>
      </VStack>
    </ChakraProvider>
  );
}

export default App;
