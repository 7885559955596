import React from "react";
import {
  Box,
  Flex,
  HStack,
  Stack,
  Image,
  Button,
  useDisclosure,
  IconButton,
  Link,
  useColorMode,
  useColorModeValue,
  ChakraProvider,
  VStack,
  theme,
  Spacer,
  Heading,
  ReactSelect,
} from "@chakra-ui/react";

import {
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaFacebook,
  FaTwitter,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ColorModeSwitcher } from "../ColorModeSwitcher";

import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";

const Pages = [
  {
    id: "Home",
    href: "/",
  },
  {
    id: "Profile",
    href: "/",
  },
  {
    id: "Portfolio",
    href: "/",
  },
];

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={children.href}
    passHref={children.id === "Home" ? false : true}
  >
    {children.id}
  </Link>
);

export default function NavBar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bg={useColorModeValue("gray.50", "gray.900")} px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ lg: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={3} alignItems={"center"}>
          <Image
            minWidth="10"
            minHeight="10"
            ml="2"
            verticalAlign="middle"
            src="/gran.png"
            alt="Granwyn Tan"
            width={10}
          />
          <Heading
            verticalAlign="middle"
            minWidth="40"
            textAlign="left"
            size="md"
            fontWeight="bold"
            ALIGN="CENTER"
          >
            Granwyn Tan
          </Heading>
          <HStack as={"nav"} spacing={6} display={{ base: "none", md: "flex" }}>
            {Pages.map((page) => (
              <NavLink key={page.id}>{page}</NavLink>
            ))}
          </HStack>
        </HStack>
        <Spacer></Spacer>
        <Flex display={{ base: "none", lg: "flex" }} alignItems={"center"}>
          <IconButton
            minWidth="10"
            ml="2"
            icon={<FaInstagram />}
            onClick={() => window.open("https://www.instagram.com/granwyntan/")}
          ></IconButton>
          <IconButton
            minWidth="10"
            ml="2"
            icon={<FaFacebook />}
            onClick={() => window.open("https://www.facebook.com/GranwynTan")}
          ></IconButton>
          <IconButton
            minWidth="10"
            ml="2"
            icon={<FaLinkedin />}
            onClick={() =>
              window.open("https://www.linkedin.com/in/granwyntan/")
            }
          ></IconButton>
          <IconButton
            minWidth="10"
            ml="2"
            icon={<FaTwitter />}
            onClick={() => window.open("https://twitter.com/GranwynTan/")}
          ></IconButton>
          <IconButton
            minWidth="10"
            ml="2"
            icon={<FaGithub />}
            onClick={() => window.open("https://github.com/granwyntan")}
          ></IconButton>
          <IconButton
            minWidth="10"
            ml="2"
            icon={<MdEmail />}
            onClick={() => window.open("mailto:granwyn_tan@s2019.ssts.edu.sg")}
          ></IconButton>
        </Flex>
        <Flex alignItems={"center"}>
          <ColorModeSwitcher ml="4" minWidth="10" />
        </Flex>
      </Flex>

      {isOpen ? (
        <Stack>
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Pages.map((page) => (
                <NavLink key={page.id}>{page}</NavLink>
              ))}
            </Stack>
          </Box>
          <HStack pb={4} display={{ base: "flex", lg: "none" }}>
            <IconButton
              minWidth="10"
              ml="2"
              icon={<FaInstagram />}
              onClick={() =>
                window.open("https://www.instagram.com/granwyntan/")
              }
            ></IconButton>
            <IconButton
              minWidth="10"
              ml="2"
              icon={<FaFacebook />}
              onClick={() => window.open("https://www.facebook.com/GranwynTan")}
            ></IconButton>
            <IconButton
              minWidth="10"
              ml="2"
              icon={<FaLinkedin />}
              onClick={() =>
                window.open("https://www.linkedin.com/in/granwyntan/")
              }
            ></IconButton>
            <IconButton
              minWidth="10"
              ml="2"
              icon={<FaTwitter />}
              onClick={() => window.open("https://twitter.com/GranwynTan/")}
            ></IconButton>
            <IconButton
              minWidth="10"
              ml="2"
              icon={<FaGithub />}
              onClick={() => window.open("https://github.com/granwyntan")}
            ></IconButton>
            <IconButton
              minWidth="10"
              ml="2"
              icon={<MdEmail />}
              onClick={() =>
                window.open("mailto:granwyn_tan@s2019.ssts.edu.sg")
              }
            ></IconButton>
          </HStack>
        </Stack>
      ) : null}
    </Box>
  );
}
