import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  Divider,
  useColorMode,
} from "@chakra-ui/react";

export default function Footer() {
  const { colorMode } = useColorMode();

  return (
    <Box>
      <Divider mt="8" />
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text
          style={{ margin: "0", textAlign: "center", fontSize: 13 }}
          color=""
        >
          Proudly Made in 🇸🇬
          <br />
          ©2022 by{" "}
          <Link
            color={colorMode === "light" ? "#2B6CB0" : "#90CDF4"}
            href="https://www.instagram.com/granwyntan/"
            target="_blank"
            rel="noreferrer"
          >
            Granwyn Tan
          </Link>
          . All Rights Reserved.
        </Text>
      </Container>
    </Box>
  );
}
