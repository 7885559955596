import {
  Container,
  Heading,
  Text,
  Box,
  useColorMode,
  AspectRatio,
  Stack,
  HStack,
  Divider,
  Spacer,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup
} from "@chakra-ui/react";
import React from "react";

function Body() {
  const { colorMode, toggleColorMode } = useColorMode();
  const colors = useColorModeValue(
    ['red.50', 'teal.50', 'blue.50'],
    ['red.900', 'teal.900', 'blue.900'],
  )
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.50'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )

  const [tabIndex, setTabIndex] = React.useState(0)
  const bg = colors[tabIndex]
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const [scrollBehavior, setScrollBehavior] = React.useState('inside')

  const btnRef = React.useRef()
  return (
    <Container
      maxW="container.lg"
      py={4}
      spacing={4}
      justify={{ base: "left", md: "left" }}
      align={{ base: "left", md: "left" }}
    >
      <Heading fontSize="30px" mt="4">
        Education
      </Heading>
      <Stack alignItems="left" mt="4">
        <Image
          objectFit="contain"
          minWidth="200px"
          ml="2"
          verticalAlign="middle"
          src="/sst.png"
          alt="Granwyn Tan"
          width={10}
        />
        <HStack alignItems={"center"}>
          <Text fontSize="20px">
            <b>School of Science and Technology, Singapore</b>
          </Text>
          <Spacer></Spacer>
          <AspectRatio maxH="40px" minW="140px" maxW="240px" ratio={4 / 3}>
            <Box
              p={2}
              borderRadius="md"
              bg={colorMode === "light" ? "gray.100" : "gray.500"}
            >
              <Text as="kbd">
                2019-<b>Present</b>
              </Text>
            </Box>
          </AspectRatio>
        </HStack>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Text>
      </Stack>
      <Stack alignItems={"left"} mt="4">
        <Image
          objectFit="contain"
          minHeight="80px"
          minWidth="80px"
          ml="2"
          rounded="md"
          verticalAlign="middle"
          src="/sap.png"
          alt="Granwyn Tan"
          width={10}
        />
        <HStack alignItems={"center"}>
          <Text fontSize="20px">
            <b>Swift Accelerator Programme</b>
          </Text>
          <Spacer></Spacer>
          <AspectRatio maxH="40px" minW="140px" maxW="240px" ratio={4 / 3}>
            <Box
              p={2}
              borderRadius="md"
              bg={colorMode === "light" ? "gray.100" : "gray.500"}
            >
              <Text as="kbd">2020-2021</Text>
            </Box>
          </AspectRatio>
        </HStack>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Text>
      </Stack>
      <Stack alignItems={"left"} mt="4">
        <Image
          objectFit="contain"
          minHeight="80px"
          minWidth="80px"
          ml="2"
          rounded="md"
          verticalAlign="middle"
          src="/fmsp.png"
          alt="Granwyn Tan"
          width={10}
        />
        <HStack alignItems={"center"}>
          <Text fontSize="20px">
            <b>Fairfield Methodist School (Primary)</b>
          </Text>
          <Spacer></Spacer>
          <AspectRatio maxH="40px" minW="140px" maxW="240px" ratio={4 / 3}>
            <Box
              p={2}
              borderRadius="md"
              bg={colorMode === "light" ? "gray.100" : "gray.500"}
            >
              <Text as="kbd">2013-2018</Text>
            </Box>
          </AspectRatio>
        </HStack>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Text>
      </Stack>
      <Divider orientation="horizontal" mt="8" mb="4" />
      <Heading fontSize="30px" mt="4" mb="4">
        Leadership
      </Heading>
      <Divider orientation="horizontal" mt="8" mb="4" />
      <Heading fontSize="30px" mt="4" mb="4">
        Service
      </Heading>
      <Divider orientation="horizontal" mt="8" mb="4" />
      <Heading fontSize="30px" mt="4" mb="4">
        Projects
      </Heading>
      <Divider orientation="horizontal" mt="8" mb="4" />
      <Heading fontSize="30px" mt="4" mb="4">
        Programming
      </Heading>
      <Divider orientation="horizontal" mt="8" mb="4" />
      <Heading fontSize="30px" mt="4" mb="4">
        Languages
      </Heading>

      <Tabs rounded="md" mb="4" onChange={(index) => setTabIndex(index)} bg={bg} variant="enclosed" isFitted>
      <TabList>
        <Tab>Red</Tab>
        <Tab>Teal</Tab>
        <Tab>Blue</Tab>
        <Tab isDisabled>Disabled</Tab>
      </TabList>
      <TabPanels p='2rem'>
        <TabPanel>The Primary Colors</TabPanel>
        <TabPanel>Are 1, 2, 3</TabPanel>
        <TabPanel>Red, yellow and blue.</TabPanel>
      </TabPanels>
    </Tabs>

      <Accordion allowToggle allowMultiple mb="4">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Section 1 title
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Section 2 title
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <RadioGroup value={scrollBehavior} onChange={setScrollBehavior}>
        <Stack direction='row'>
          <Radio value='inside'>inside</Radio>
          <Radio value='outside'>outside</Radio>
        </Stack>
      </RadioGroup>

      <Button mt={3} ref={btnRef} onClick={() => {
        setOverlay(<OverlayOne />)
        onOpen()
        }}>
        Trigger modal
      </Button>

      <Modal 
        mb="4px"
        onClose={onClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        // blockScrollOnMount={false}
        scrollBehavior={scrollBehavior}
        isCentered
      >
        {overlay}
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
          </ModalBody>
          <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
              OK
            </Button>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant='ghost'>Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Spacer height="15px"></Spacer>
      <RadioGroup defaultValue="4">
        <Stack spacing={5} direction='column'>
          <Radio size='sm' colorScheme='red' value='1'>
            Unchecked
          </Radio>
          <Radio size='md' colorScheme='green' value='2'>
            Unchecked
          </Radio>
          <Radio size='lg' colorScheme='orange' value='3'>
            Unchecked
          </Radio>
          <Radio value='4' isDisabled defaultChecked>
            Checked
          </Radio>
          <Radio isInvalid>
            Invalid
          </Radio>
        </Stack>
      </RadioGroup>
    </Container>
  );
}

export default Body;
