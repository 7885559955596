import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  IconButton,
  Divider,
  Container,
} from "@chakra-ui/react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function Header() {
  return (
    <Container maxW="container.lg" mt="2" mb="2" pt="4">
      <VStack alignItems="left" spacing="4">
        <VStack alignItems="left">
          <Heading fontSize="45px">Granwyn Tan 陈冠宇</Heading>
          <Text fontSize="18px">
            Student, Astronomy Enthusiast, Designer, Coder
          </Text>
          <Text fontSize="18px">学生、天文爱好者、设计师和编码员</Text>
        </VStack>
        <Divider orientation="horizontal" />
        <Text>
          Hi there! 👋 I'm <b>Computing</b> <b>Student</b> from{" "}
          <b>School of Science and Technology, Singapore</b> 🇸🇬 who's passionate
          about <b>Programming</b> and <b>Learning Languages</b>.
        </Text>
        <Text>
          你好！👋 我是来自<b>新加坡</b>🇸🇬的一位<b>计算</b>
          <b>学生</b>。我对<b>编码</b>以及<b>学习</b>各种<b>语言</b>充满热情。
        </Text>
      </VStack>
    </Container>
  );
}
